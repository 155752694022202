export default {
	data() {
		return {
			articleContent:[
				[
					{ type: 'fristTitle', val: '香港——世界金融中心的强大地位' },
					{ type: 'newsTime', val: '发布时间：2023年10月9日' },
					{ type: 'divider', val: '' },
					{ type: 'typeTexts', val: '香港是唯一一个集合中国优势和环球优势于一身的城市，又是世界前列的国际金融中心。香港位于亚洲的中心地带，与内地紧密融合，并接通世界各地。一直是海外投资内地的窗口、中资“走出去”的跳板。多年来建立起一套自由、透明并且符合国际标准规范的金融体系，其国际金融中心地位坚韧、稳固，并获得国际广泛认同。', mark: false },
					{ type: 'images', val: 'art1/art_1_1.jpeg' },
					{ type: 'secondTitle', val: '领先世界的自由经济体' },
					{ type: 'typeTexts', val: '美国著名《时代》杂志，曾推出封面文章以“三城记”作为封面专题文章，对纽约、伦敦和香港在全球金融体系中的独特地位进行了详细阐述并创造了一个新名词Nylonkong(“纽伦港”)。标志着香港已成为同纽约、伦敦同一水平的全球金融中心的典范，香港已经构建成为促进全球经济发展的金融网。', mark: true },
					{ type: 'typeTexts', val: '加拿大弗雷泽研究所2022年9月8日发布《世界经济自由度2022年度报告》，在165个经济体中，香港继续评为全球最自由经济体，自报告发布以来，香港一直位居全球第一。', mark: true },
					{ type: 'images', val: 'art1/art_1_2.jpeg' },
					{ type: 'typeTexts', val: '资料来源：弗雷泽研究所《世界经济自由度2022年度报告》', mark: false },
					{ type: 'typeTexts', val: '2023年5月31日，国际货币基金组织执行董事会发布关于香港经济及金融状况的评估报告中，国际货币基金组织再次肯定香港国际金融中心地位。报告认为香港作为主要国际金融中心，有稳健的制度框架、充裕的资本和流动性缓冲，对金融业规管水平甚高，联系汇率制度亦畅顺运作。', mark: true },
					{ type: 'secondTitle', val: '香港稳居世界一线城市' },
					{ type: 'typeTexts', val: '由全球化与世界城市（GaWC）研究网络编制的全球城市分级排名——《世界城市名册2020》正式出炉，该榜单被认为是全球最权威的世界城市排名。' },
					{ type: 'typeTexts', val: 'GaWC以其独特视角对城市进行Alpha，Beta，Gamma， Sufficiency（+/-）划分（即：全球一、二、三、四线），以表明不同城市在全球化经济中的位置及融入度。从2020年入围榜单来看，Alpha以上等级城市中有6座中国城市入围，其中香港、上海、北京同为Alpha+，其中香港对外的连接性会相对均衡，在全球化经济中，将继续是环球经济增长的引擎。' },
					{ type: 'images', val: 'art1/art_1_3.png' },
					{ type: 'typeTexts', val: '资料来源：全球化与世界城市研究网《世界城市名册2020》' },
					{ type: 'secondTitle', val: '国际化的金融市场地位' },
					{ type: 'typeTexts', val: '香港作为国际金融中心，有着国际化的金融市场、健全的金融基建配套及规范的金融监管，凭着在银行、资本市场和资产管理、风险管理方面的优势，香港为来自世界各地的投资者、融资者、资产管理人、基金和金融机构，提供全方位和高质素的金融平台。香港金融市场在有效、透明，以及符合国际标准的监管下运作。多个金融领域均处于国际领先地位：亚洲领先的国际资产管理中心、全球最大的公开招股市场、全球最大的人民币外汇交易市场以及亚洲第二大的外汇市场，香港保险市场的保险密度和保险渗透率常年位居亚洲第一位。' },
					{ type: 'images', val: 'art1/art_1_4.png'},
					{ type: 'typeTexts', val: '数据来源：香港金管局' },
					{ type: 'secondTitle', val: '香港的国际金融中心地位坚韧稳固' },
					{ type: 'images', val: 'art1/art_1_5.jpg' },
					{ type: 'typeTexts', val: '香港近年来历经“修例风波”、外围经贸摩擦、美国制裁、新冠肺炎疫情等多重挑战，但是香港作为国际金融中心的竞争力并无减弱，香港凭借稳健的金融基建和健全的监管制度稳定运行享誉全球，时间和事实已经并将继续提供最好的证明。国家“十四五”规划及中央金融工作会议明确提出，支持香港巩固和提升国际金融中心地位。香港特区行政长官李家超多次提到香港国际金融中心是重中之中，要强化全球离岸人民币业务枢纽功能、国际资产管理中心、风险管理中心，以及其他金融市场业务。展望未来香港可发挥好联结内地与国际市场的桥头堡作用，持续优化各项金融市场联通机制，更好地满足国内外投资者的需求，巩固在全球金融市场的领先地位。' },
				],
				[
					{ type: 'fristTitle', val: '香港保险九大核心优势' },
					{ type: 'newsTime', val: '发布时间：2023年10月16日' },
					{ type: 'divider', val: '' },
					{ type: 'typeTexts', val: '香港是全球最自由的金融中心之一，长期以来扮演内地与国际互联互通的“超级联系人”。香港保险公司可以投资全球市场、市场化程度高、资金配置可实现全球的统筹和集散，是储蓄保单稳健分红的重要支撑。2023年2月中国香港与内地全面恢复通关后，内地居民赴港投保呈现爆发式增长。2023年上半年内地访客赴港投保保费319亿港元，同比大幅上升5,815%，319亿港元的新单保费，超越了2016年港险“巅峰时代”的同期水平（301亿港元），创下历史上半年内地赴港投保数据新高。内地居民为何热衷打飞的去香港购买保险香港保险的优势究竟在哪里？' },
					{ type: 'images', val: 'art2/art_2_1.jpeg' },
					{ type: 'thirdTitle', val: '一、多种货币相互转化' },
					{ type: 'typeTexts', val: '在全球通胀、经济衰退的大背景下，一些国家币值的贬值速度可能远超于其他国家。地缘政治博弈带来很大的不确定性，<b>无论是人民币还是美元，都可能存在汇率风险，单一货币的风险加大。</b>疫情前，去香港买分红险，基本上只能选美元、港币保单，而且两者之间不可转换。但从2022年开始，香港各大保险公司推出了可转换保单货币的长期储蓄计划，让客户可以根据时事变化和个人需要来更换保单货币，以此来应对潜在的货币风险。', isHtml: true },
					{ type: 'images', val: 'art2/art_2_2.jpg' },
					{ type: 'typeTexts', val: '一些保险产品可以涵盖人民币、美元、英镑、澳元、加元、欧元、新元、港元、澳门币9种世界主流货币，保单货币可自由转换。并且通常第3个保单周年日起，每年可转换一次保单货币。因此，<b>通过配置香港保险，可以实现配置多种货币资产的需求，分散单一货币风险，对冲通货膨胀、资产缩水的风险，收益更稳健。</b>对于部分熟悉国际金融市场的客户，可以用每年一次转换保单货币的机会来抓住市场机遇，获得超额收益。另外，如果给孩子配置保险，孩子未来不论去哪个国家生活、工作，可以先用多币种的香港分红险储备资金，在哪里学习、工作、生活就换哪种货币。',isHtml: true },
					{ type: 'thirdTitle', val: '二、红利收益可锁定' },
					{ type: 'typeTexts', val: '香港保险公司会根据自己的投资经验对分红投资资金做管理，但或多或少会受到资本市场表现的影响。不过，现在的香港分红保单有分红锁定功能。一些多元货币储蓄产品从第15个保单周年日起，每年可以锁定分红收益<b>（复归红利和终期红利）</b>，把波动的分红变为固定分红，可以让客户在市场表现优异的时候可以选择部分锁定分红，落袋为安，让客户对于保单多一层的掌控感。并且，有的产品也有<b>红利解锁功能，可以随时提取现金，</b>或者留在保险公司的账户里赚取一定的利息，进一步方便客户选择在不同经济周期完成各种操作，提高自己的收益，让保单在稳健增值的同时，也能提供灵活的现金流。',isHtml: true },
					{ type: 'thirdTitle', val: '三、被保人可<span style="color:#2453d5">无限次转换</span>',isHtml: true },
					{ type: 'typeTexts', val: '保险短期持有的收益率是较低的，长期持有的收益率才高。过往保单的被保人万一身故，那么这份保单就结束了，后续潜在收益也就跟着结束了。如果能够在被保人身故时，把被保人更改为家人，那么这份保单的收益可以继续享有，让家庭不断地从保单中获得长期收益。有了<b>“无限次更改被保人”</b>这一功能，才能真正实现：爸爸的保单被保人改为成年后的投保人，投保人老了再把保单被保人改为子女，子女再把保单被保人改为孙辈，一份保单，代代相传。',isHtml: true },
					{ type: 'thirdTitle', val: '四、保单可拆分' },
					{ type: 'typeTexts', val: '现在的<b>香港保单可拆分成N份</b>，这样便可根据投保人的需求来分配给家人，不用强制把保单退保或部分退保，避免因退保带来的金钱损失。同时还可以透过保单货币转换功能，把拆分后的保单指定为不同货币，这样如果家人去到世界不同国家或地区，也可以游刃有余<b>保单拆分功能让保单更灵活、传承功能更强，不仅可以保障终身，也便于进行资产传承。</b>若加以利用合适的保单或专为税务责任设计的金融工具，亦可减轻部分税务负担。一份保单，全家共享。',isHtml: true },
					{ type: 'images', val: 'art2/art_2_3.jpeg' },
					{ type: 'thirdTitle', val: '五、保险金信托' },
					{ type: 'typeTexts', val: '目前，香港大部分保单都有了保险金信托的功能，无门槛，无费用，保单持有人可以在身故前向保险公司要求安排该功能（不收取任何费用）。在被保险人身故后，保险理赔金不会一次性向保单受益人发放，而是会按照保单持有人的生前意愿进行分期、分额发放，有效避免了受益人“挥霍”理赔款的问题。' },
					{ type: 'thirdTitle', val: '六、保费融资' },
					{ type: 'typeTexts', val: '香港储蓄保险有一个内地保险尚不具备的“独到优势”——大额寿险保单的保费融资。保费融资是投保人、保险公司、银行三方围绕“保险合同”展开的合作，其操作方式类似于贷款买房：投保人向保险公司缴纳一定的“首付”，再向银行贷款一定金额购买大额寿险保单，投保成功后，保单将作为放贷的抵押物交由银行保管，直至贷款还清。保费融资的核心在于贷款利息低于保单的收益率，由此形成套利空间，这几年逐渐成为香港保险最受欢迎的投资方式，但在内地市场，银行贷款利息远高于保单收益，也就没必要操作了。' },
					{ type: 'thirdTitle', val: '七、重疾保额终身分红' },
					{ type: 'typeTexts', val: '随着医疗技术的发展，通货膨胀的影响，国内现在的重疾险已定无法解决客户未来生病住院的治疗花费需求，香港保险独有的特色：<b>重疾险首十年保额赠送35%-100%，且保额终身有分红。确诊即理赔，无补充条款约束。</b>',isHtml: true },
					{ type: 'images', val: 'art2/art_2_4.jpeg' },
					{ type: 'thirdTitle', val: '八、较高的分红实现率' },
					{ type: 'typeTexts', val: '香港的储蓄分红险，虽不承诺保本保息，但合同条款中有<b>“保证金额+非保证金额（分红）”</b>组成，往往数年时间，综合保单价值就已超过本金。很多香港储蓄分红险的中长期预期收益（IRR）可以达5%-7%左右。虽然分红存在不确定性，但根据相关监管的规定，保险公司需要在官网披露“分红实现率”，<b>目前大多数公司的分红实现率在95%-110%之间</b>。香港保监局规定，<b>保险公司的所有分红保险产品必须每年公布分红实现率，</b>也就是说保单的实际分红与预计分红的比率，<b>客户可以通过保险公司的官网查询每年的分红履行情况。</b>香港分红险的高“预期分红”在严格监管下，理性、透明度强，也使各家保险公司必须要重视非保证红利的承诺兑现。',isHtml: true },
					{ type: 'thirdTitle', val: '九、严审核、宽理赔、全球化服务' },
					{ type: 'typeTexts', val: '香港是全球最发达、最开放的保险市场之一，凭借得天独厚的优势，香港成为国内外资金往来、贸易往来的中转站，是国内走出去、国外走进来的“超级联络人”。香港保险市场的保险渗透率排名全球第一位，保险密度排名亚洲第一位，全球20大保险公司中，有13家在香港获授权经营保险业务。香港实行的是<b>“严核保、宽理赔”</b>的政策，在正式投保前，需要签署许多告知书。但在签订了保险合同后，理赔时只要提交相关资料，就可以及时快速地获得赔付。因此，香港保险的理赔流程是非常方便快捷的。',isHtml: true },
				],
				[
					{ type: 'fristTitle', val: '香港保险历经百年，恰是风华正茂' },
					{ type: 'newsTime', val: '发布时间：2023年10月23日' },
					{ type: 'divider', val: '' },
					{ type: 'typeTexts', val: '保险作为现代金融业三驾马车之一，在香港的发展历史悠久。从1841年香港第一家保险公司诞生至今，香港保险已走过了180多年的风风雨雨，近年来受益于大陆经济的崛起，香港保险业步入快速发展阶段并持续增长，保险业已成为香港经济发展的支柱。香港已成为全球最成熟的保险市场之一，有着发达的保险市场体系，并始终保持创新，保险配置也成为个人或家庭财富规划中不可或缺的一部分。' },
					{ type: 'redTitleCenter', val: '香港保险百年发展历程' },
					{ type: 'secondTitle', val: '初始起步期（1841——1941）：海外贸易推动保险起步' },
					{ type: 'typeTexts', val: '1805年，为方便支付海外贸易产生的保险赔款，由怡和、宝顺两家外商洋行牵头，在广州创立了第一家在华外资保险公司——“谏当保安行”，也称“广州保险会社”。1841年英军占领香港后，谏当保安行也从广州迁到了香港，成为香港最早的一家保险公司。' },
					{ type: 'images', val: 'art3/art_3_1.jpeg', minWidth: '50%'},
					{ type: 'secondTitle', val: '战争期（1941——1945）：保险行业备受打击' },
					{ type: 'typeTexts', val: '1941年12月，太平洋战争爆发，日军占领香港全境，香港保险行业在占领者的铁蹄下备受摧残。大量的保单丢失或毁坏，保险公司也经历了巨额赔款，因此保险业受到了沉重的打击。' },
					{ type: 'images', val: 'art3/art_3_2.png'},
					{ type: 'secondTitle', val: '蓬勃发展期（1945—1997）：外资保险在港落地' },
					{ type: 'typeTexts', val: '新中国成立后，西方各国的保险公司纷纷将原来设在中国内地的机构迁移至香港。随着经济的腾飞，香港保险业也开始突飞猛进的发展。' },
					{ type: 'typeTexts', val: '据港英当局统计：截止1993年1月31日，香港保险市场上拥有230家保险公司，其中寿险公司40家，产险公司170家，混合公司20家，有从业人员18500名。' },
					{ type: 'images', val: 'art3/art_3_3.jpg'},
					{ type: 'secondTitle', val: '新发展时期（1997—至今）：国际保险配置中心地位日渐牢固' },
					{ type: 'typeTexts', val: '1997年香港回归祖国，香港的保险业发展也进入了一个全新的时期，国际保险配置中心地位日渐牢固。香港保险业在建设香港成为全球领先的国际金融中心方面扮演重要角色，香港保险历经百年洗礼正以其成熟、稳健、高效、透明的姿态吸引世界投资者的目光。' },
					{ type: 'images', val: 'art3/art_3_4.png'},
					{ type: 'redTitleCenter', val: '当前香港保险市场发展格局' },
					{ type: 'secondTitle', val: '香港保险密度和深度在全球名列前茅' },
					{ type: 'typeTexts', val: '2021年香港保险密度(人均保费)达9706美元，2021年香港保险深度(保费占GDP比重)高达19.6%，从国际上看，世界平均水平是7%左右，发达国家和地区10%左右，香港保险深度排名世界第一，保险密度排名亚洲第一。' },
					{ type: 'images', val: 'art3/art_3_5.png', minWidth: '90%'},
					{ type: 'typeTexts', val: '资料来源：wind' },
					{ type: 'secondTitle', val: '香港保险业整体规模稳定增长' },
					{ type: 'typeTexts', val: '近年来香港保险业蓬勃发展总保费从2012年的2633亿，增长至2022年的5560亿，年复合增速10%。近三年来，伴随疫情下封关、全球经济动荡，香港保险市场增速有所放缓但总保费仍稳定保持5500亿左右规模。' },
					{ type: 'images', val: 'art3/art_3_6.png', minWidth: '90%'},
					{ type: 'typeTexts', val: '数据来源：香港保险业监管局' },
					{ type: 'secondTitle', val: '高度国际化，成为全球一流保险机构聚集地' },
					{ type: 'typeTexts', val: '截至2022年底，香港共有164家保险公司获批经营，其中89家经营产险业务、53家经营寿险业务、19家经营综合业务(产险、寿险兼营)以及3家经营特定目的业务，几乎大多数的国际知名保险集团都在香港设立了分支机构或地区管理总部。其中在港交所上市保险公司共有17家，占2022年底香港资本市场总市值5%左右。' },
					{ type: 'images', val: 'art3/art_3_7.png', minWidth: '90%'},
					{ type: 'typeTexts', val: '资料来源：香港保险业监管局《2021-2022年报》' },
					{ type: 'secondTitle', val: '独立监管，坚守风险管理底线' },
					{ type: 'typeTexts', val: '香港保监局是独立于政府的保险业监管机构，遵行国际保险监督管理协会的规定，即保险监管机构应在财政和运作上独立于政府及业界，来确保保险业的规管架构与时并进，促进香港保险业的稳健发展，为保单持有人提供保障。' },
					{ type: 'images', val: 'art3/art_3_8.png', minWidth: '90%'},
					{ type: 'typeTexts', val: '数据来源：香港保险业监管局' },
				],
				[
					{ type: 'fristTitle', val: '香港保险海外资产配置的第一站' },
					{ type: 'newsTime', val: '发布时间：2023年10月30日' },
					{ type: 'divider', val: '' },
					{ type: 'typeTexts', val: '经济全球化不断发展，越来越多投资者意识到了配置海外资产的重要性。香港保险是很多中产家庭的首选，也是大部分高净值人群海外资产配置的第一站' },
					{ type: 'secondTitle', val: '全球多元化配置势在必行' },
					{ type: 'typeTexts', val: '2023年以来全球经济：从生产端看，全球制造业和工业生产疲软；从需求端看，主要经济体内外部需求逐步回落，经济活动降温从制造业蔓延至服务业；从政策环境看，贸易和科技保护主义政策不利于大宗商品和资源在全球高效流动；主要经济体经济复苏周期分化。全球历经疫情、地缘冲突、贸易保护主义抬头，全球经济增速放缓。世界各国采取不同政策提振经济，由于各国内外部环境差异较大，经济复苏程度层次不齐，大类资产表现不一。全球配置重要性越来越凸显，持有不同货币的大类资产来分散未知国别风险、汇率风险将成为资产规划的共识，多元化配置理念及分散风险的投资意识势在必行，全球化海外资产配置开始逐步提上日程。' },
					{ type: 'images', val: 'art4/art_4_1.png' },
					{ type: 'images', val: 'art4/art_4_2.png' },
					{ type: 'typeTexts', val: '资料来源：第一财经' },
					{ type: 'secondTitle', val: '海外配置需求不断增加' },
					{ type: 'typeTexts', val: '随着居民财富不断累积与对稳健投资收益的追求，海外资产配置需求持续增加。麦肯锡《金融业白皮书后疫情时代财富管理重启增长》显示：中国家庭的海外资产配置比例较发达市场仍处于较低水平，仅占个人金融资产总额的5%。未来，境外可投资资产有望持续攀升，据测算到2032年底，国内居民个人离岸资产规模将达29万亿元。'},
					{ type: 'images', val: 'art4/art_4_3.png', minWidth: '70%' },
					{ type: 'typeTexts', val: '数据来源：波士顿咨询《全球财富报告2023》' },
					{ type: 'typeTexts', val: '国内居民全球化投资意愿较高，且以富裕和高净值以上人群为主。香港有着国际化的金融市场、健全的金融基建配套及规范的金融监管，凭着在银行、资本市场和资产管理、保险保障方面的优势，香港为来自世界各地的投资者、融资者、资产管理人、基金和金融机构，提供全方位和高质素的金融平台。中国香港地区是中国内地投资者首选的主要离岸中心。波士顿咨询《全球财富报告2023》显示：2022年香港以2.2万亿美元的金融资产管理额位居全球离岸金融中心第二位。香港的大部分资金来自中国内地，波士顿咨询预计，到2027年，香港将成为唯一个管理超过3万亿美元境外资产的金融中心，跃居世界第一。 '},
					{ type: 'blueTitle', val: '个人离岸金融资产规模（人民币万亿）'},
					{ type: 'images', val: 'art4/art_4_4.png', minWidth: '70%' },
					{ type: 'typeTexts', val: '麦肯锡《金融业白皮书后疫情时代财富管理重启增长》' },
					{ type: 'secondTitle', val: '全球不确定性增加，保障类需求凸显' },
					{ type: 'blueTitle', val: ' 2022年世界寿险实际保费增长率'},
					{ type: 'images', val: 'art4/art_4_5.png' },
					{ type: 'typeTexts', val: '资料来源：瑞士再保险' },
					{ type: 'typeTexts', val: '全球经济放缓经济成本压力开始从商品传导至服务业，而今年欧美金融系统不稳定性增加了经济前景的不确定性。俄乌战争还未结束，中东巴以冲突又起，地缘冲突将推高能源、粮食等大宗商品价格，进一步刺激加剧通胀。主要国家之间日益紧张的关系，保护主义抬头，国家地区发展不平衡加速。经济衰退、社会动荡持续、自然灾害频发，越来越多的人空前清楚地意识到自己需要更强有力的保障，保护自己最为珍视的事物。国际四大会计师事务所安永研究显示全球保障缺口2022年1.4万亿美元，比2000年的缺口增加了 2倍。' },
					{ type: 'secondTitle', val: '香港保险成为海外资产配置的第一站' },
					{ type: 'typeTexts', val: '香港保险市场因独特的区位优势，连接内地和全球市场，国际保险巨头林立，实现全球一体化的多市场资产配置。大多数保险公司进行了多元货币配置，在美元保单的投资领域具有极高的深度和广度。从投资市场上看，遍及美国、欧洲、英国及亚太区，从资产类别上看，香港保险公司可以覆盖大部分资产类别，做到投资组合的高度分散。' },
					{ type: 'images', val: 'art4/art_4_6.jpeg' },
					{ type: 'typeTexts', val: '香港保险优势主要有两大类：制度优势和产品优势，可以与内陆形成有效组合，对冲风险。正如同香港和内地经济实现互联互通，这样的多元价值观，形成对外竞争合作的多个触点。香港是跨境的金融中心之一，亦是自由港，在香港的资本可以自由地在世界范围内流动，可投资范围覆盖全球多种产品，同时世界主流货币也能自由兑换。香港保单大部分是美元和港币计价，而香港的保险公司全球性运营，盈利能力和风控能力都很稳健，通过长期平滑机制，稳定市场波动，长期累积收益，抵御贬值风险。香港储蓄险产品采用高预期收益率+低保证收益率模式，在能满足全球多元化多币种的配置需求的同时又能实现资产的保值增值。' },
					{ type: 'typeTexts', val: '我们正处在一个全球资产配置的黄金时代，财富的积累、政策的放开、渠道的拓宽，让投资者拥有越来越多参与到海外市场的方式方法。香港保险配置是投资者有效对冲市场风险、追逐财富自由的第一步，也是助力财富传承，实现基业长青的有力工具。' },
				],
				[
					{ type: 'fristTitle', val: '香港保险险种功能剖析' },
					{ type: 'newsTime', val: '发布时间：2023年11月06日' },
					{ type: 'divider', val: '' },
					{ type: 'secondTitle', val: '香港保险之重大疾病险' },
					{ type: 'images', val: 'art5/art_5_1.jpg' },
					{ type: 'typeTexts', val: '香港的重大疾病险在保障疾病范围、分红、理赔等方面具有明显特点。具体包括：' },
					{ type: 'typeTexts', val: '<b>1.保障疾病范围广：</b>香港重疾险保障的疾病范围较广，可选的种类较多。保障涵盖高达100多种的疾病，包括重疾以及早期疾病。对于高发的危疾提供不分组的多重保险。', isHtml:true},
					{ type: 'typeTexts', val: '<b>2.保额有分红：</b>相比内地的保额恒定，香港重疾险将分红加进保额和现金价值，让投资者的保额在未来不断增长，能抵消一定程度上的通胀和医疗成本的上升。这也是很多人会选择香港重疾险的原因之一。', isHtml:true},
					{ type: 'typeTexts', val: '<b>3.保单具有外币属性：</b>香港重疾大部分以美元计价，全球前20大保险公司，有23家在香港设有分公司，6家在香港上市。通过持有美元保单，起到分散风险的作用，实现家庭资产的多元布局，综合平衡自己的风险和收益，同时也可以获得相应的全球理赔。', isHtml:true},
					{ type: 'typeTexts', val: '<b>4.可多次赔付：</b>大部分香港的重疾险都可以进行多次赔付，对于癌症这种极易复发的重疾来说，多次赔付可以帮助受保人节省巨大的医疗成本。在最新的重疾产品中，很多保险公司把多次赔付的期限缩短到1年，包括癌症的复发、新发或持续，从患者自身角度出发，发挥重疾险的最大价值。', isHtml:true},
					{ type: 'secondTitle', val: '香港保险之储蓄险' },
					{ type: 'images', val: 'art5/art_5_2.jpeg' },
					{ type: 'typeTexts', val: '按照保监局发布的最新保单数统计，内地访客购买数量最多的为终身寿险。这就不得不提当中最受青睐的储蓄型分红险，既有保障功能，又能获得储蓄分红。这使得香港的储蓄险在保险业享负盛名，除了高分红率之外，还有可拆分保单、可更换受保人、支持多币种等特点。'},
					{ type: 'typeTexts', val: '<b>1.高额收益率：</b>大部分香港储蓄险的中长期预期收益率可达年化5%-7%，高于内地增额终身寿险的2.5-3.0%。并且香港保险一般采取复利的形式，随着保单的时间拉长，会呈现非常可观的复利收益。如果配置储蓄险想追求更高的收益，那么香港储蓄险将会是不二之选。', isHtml:true},
					{ type: 'typeTexts', val: '<b>2.可拆分保单：</b>香港部分储蓄保单可拆分成N份，这样可以根据自己的需要按照设定的比例和份数来分配给家人，而不用强制把保单退保或部分退保，避免退保带来的金钱损失，是一个非常人性化的设计。但是，拆分后的保单不可以逆转。', isHtml:true},
					{ type: 'typeTexts', val: '<b>3.可更换受保人：</b>香港储蓄险的另一大优势，保单持有人可以在第一个保单周年日起，申请转换受保人，甚至可以无限次更改被保人，这使得保单可以一直持续下去，实现保单传承。', isHtml:true},
					{ type: 'typeTexts', val: '<b>4.支持多币种转换：</b>目前香港的储蓄险大多支持7-9种主流币种的自由转化。包括美元、人民币、港币、英镑、欧元等9种主要币种。通常在第三个保单周年日起，保单持有人每年可进行一次货币转化。方便有海外资产配置需求，或者有出国留学、定居、养老的需求人士。', isHtml:true},
					{ type: 'typeTexts', val: '<b>5.锁定紅利：</b>储备保险提供相对稳定的潜在长期资产增值，一般的储蓄保险都会提供保证现金价值，而有些储蓄保险亦提供「非保证终期紅利」，投保人可以锁定这部分红利，並积存在保单内，以赚取非保证利息，或随时提取这些红利，加以灵活运用。', isHtml:true},
					{ type: 'typeTexts', val: '<b>6.类信托功能：</b>很多保险于全数退保和身故时，除可一笔领取外，计划亦提供定期或递增给付支付方式，身故赔偿更可以一笔过部分领取，而余额以定期方式领取，不用传统信托高昂收费，让客户提早规划完美人生，令下一代生活幸福无忧。', isHtml:true},
					{ type: 'secondTitle', val: '香港保险之高端医疗险' },
					{ type: 'images', val: 'art5/art_5_3.jpg' },
					{ type: 'typeTexts', val: '相比普通的商业健康险，高端医疗险保额更高、医院覆盖范围更大，使受保人就医更加人性化、保障更为充足。高端医疗险往往适合高净值人群以及中产阶层以上的人士，他们由于工作强度大，时间紧张，对自身的身体状况和医疗保障要求很高。下面一起来了解一下香港高端医疗有哪些优势：'},
					{ type: 'typeTexts', val: '<b>1.保额更高：</b>很多保险于全数退保和身故时，除可一笔领取外，计划亦提供定期或递增给付支付方式，身故赔偿更可以一笔过部分领取，而余额以定期方式领取，不用传统信托高昂收费，让客户提早规划完美人生，令下一代生活幸福无忧。', isHtml:true},
					{ type: 'typeTexts', val: '<b>2.全球医疗服务网络：</b>相比普通医疗保险要限制医院的选择，香港高端医疗保险对医院的选择范围覆盖非常大。保险公司与全球大多数医疗机构都有良好的合作关系，尤其是香港、新加坡、日本、澳大利亚等。如果您想要产品既涵盖储蓄又可以有高端医疗的成分，现在市面上也非常流行储蓄医疗A+B计划，透过储蓄险的红利对医疗险进行供款，可谓一举两得，省心省力。', isHtml:true},
					{ type: 'secondTitle', val: '香港保险之寿险' },
					{ type: 'images', val: 'art5/art_5_4.jpg' },
					{ type: 'typeTexts', val: '香港的寿险产品十分丰富，拥有高预期回报、简易信托等特点，具体包括：'},
					{ type: 'typeTexts', val: '<b>1.预期回报高：</b>香港的人寿保险身故赔偿高，收益率也非常有竞争力。长期寿险因投资时限长，香港及其他国家能投资的品类丰富，能投资的优质大类资产更多，因此预期收益率相比普通寿险优质更高。保障和投资性质兼得。', isHtml:true},
					{ type: 'typeTexts', val: '<b>2.简易信托：</b>香港不少终身寿险都加入了简易信托的功能，保单持有人可以在身故前向保险公司要求安排该功能，在受保人身故后，保险理赔金不会一次性向保单受益人发放，而是会按照保单持有人的生前意志进行分期发放，有效避免了受益人“挥霍”理赔款的问题。', isHtml:true},
					{ type: 'typeTexts', val: '<b>3.保单具有分红属性：</b>赔付额会叠加分红进行增长，也可以抵抗通胀。', isHtml:true},
				],
				[
					{ type: 'fristTitle', val: '香港保险背后的投资与保障' },
					{ type: 'newsTime', val: '发布时间：2023年11月13日' },
					{ type: 'divider', val: '' },
					{ type: 'typeTexts', val: '香港保险以高收益率和高额赔付而闻名，香港保险公司到底是如何带来这么高的收益和赔付，保费资金去向哪里？又是如何保障的呢？带你揭秘香港保险背后的投资与保障。' },
					{ type: 'secondTitle', val: '保费资金去哪里了？' },
					{ type: 'images', val: 'art6/art_6_1.jpg' },
					{ type: 'typeTexts', val: '&emsp;&emsp;很多人会有疑惑，保险公司哪来的钱给投保人分红或者赔付呢。简单来讲，保险公司将收缴的保费再加上其一部分自有资金，用来投资运作，从而获取投资收益，再分红或赔付给投保人。那获得收益的高低自然就和投资范围、投资品种和投资理念息息相关。', isHtml:true },
					{ type: 'redTitleLeft', val: '投资范围' },
					{ type: 'typeTexts', val: '&emsp;&emsp;香港地区作为全球性金融中心，其保险业历史自1841年诞生至今已有176年的历史。近年来受益于大陆经济的崛起，香港保险业步入快速发展阶段并持续增长，保险业已成为香港经济发展的支柱，香港也因此成为全球领先的主要国际保险中心。', isHtml:true },
					{ type: 'images', val: 'art6/art_6_2.png' },
					{ type: 'typeTexts', val: '资料来源： 香港保险业监督管理局' },
					{ type: 'typeTexts', val: '香港保险业监督管理局信息显示，香港的保险渗透率全球及亚洲排名第二，保险密度更达到亚洲第一，全球第二。香港保险业作为国际保险业中心的一个主要表现，就是其保险资金运用可以进行全球资产配置。  作为全球最发达保险市场之一，截至2023年3月31日，香港共有164间获授权保险公司，其中包含众多国际顶级保险集团将其国际业务总部或者亚太地区业务总部设在香港。因此，香港保险业资金可以通过成熟的国际金融市场进行全球范围内的投资，以获取更高的投资收益。' },
					{ type: 'redTitleLeft', val: '投资项目种类' },
					{ type: 'typeTexts', val: '&emsp;&emsp;香港保险资金主要有两大类投资：固定收益类资产包括国债、企业债和其他固收类资产等和权益类资产包括上市股票、对冲基金、共同基金、私募股权和房地产等。保险产品收益的确定是由其背后的资产投资组合所决定的。一个保险产品究竟能给到投保人多少的保证收益和非保证收益，主要取决于产品背后各配置了多少比例的固收类资产和权益类资产。一般而言，固定收益类资产的收益较为稳定，但收益率通常比权益类资产低，但权益类资产的高收益通常亦会面临较高风险。因此，相比于内地，香港保险在全球范围下的投资品种较为丰富，通常会比内地保险投资配置更多比例的权益类资产以提高长远的投资表现。', isHtml:true },
					{ type: 'redTitleLeft', val: '投资理念' },
					{ type: 'typeTexts', val: '&emsp;&emsp;香港保险公司的投资风格相对稳健，通过比较广泛的投资组合，为保单持有人在可接受的风险水平下争取最高回报。在对保险资金的运用上也更注重长期价值投资，避免过度投机。例如，在保证回报较高的保险计划中，会分配更低比例的高风险资产，反之则分配更高的比例。此外，在投资策略上一般会采用积极主动的管理，会因市场情况的转变而调整。同时也会根据保单的币种做出直接投资或货币对冲，降低保单的货币风险。', isHtml:true },
					{ type: 'typeTexts', val: '&emsp;&emsp;香港保险业的承保长期保险业务（类别C业务除外）指引（指引16）（GL16），要求香港各大保险公司在自己的官方网站上披露公司对于分红保险业务的投资理念和经营策略（各保险公司官网），投资者可以自行查看这些分红险产品的投资投资策略、投资标的、风控措施、分红理念等。', isHtml:true },
					{ type: 'secondTitle', val: '如何保障收益与赔付' },
					{ type: 'images', val: 'art6/art_6_3.jpeg' },
					{ type: 'typeTexts', val: '&emsp;&emsp;高额的收益和赔付需要有力的保障才能让保单持有人放心。为此，香港保险业监管局制订了一系列严格监管政策，保险公司也推出了保障收益的创新制度。', isHtml:true },
					{ type: 'redTitleLeft', val: '充足的偿付准备金' },
					{ type: 'typeTexts', val: '&emsp;&emsp;香港保险业监管规定，保险公司须把其资产多于负债的数额，维持在不少于条例规定的偿付准备金水平。这项规定的目的是在面对保险公司出现不可预知的情况下(例如当其经营业绩或其资产与负债的价值出现不利波动时)，而有可能出现其资产不足以应付其负债的风险时，为投保人提供合理的保障。', isHtml:true },
					{ type: 'images', val: 'art6/art_6_4.png', minWidth: '70%' },
					{ type: 'images', val: 'art6/art_6_5.png', minWidth: '80%' },
					{ type: 'redTitleLeft', val: '充足的再保险安排' },
					{ type: 'typeTexts', val: '&emsp;&emsp;《保险业条例》规定，保险公司须为其拟经营的保险类别的风险备有或将会作出足够的再保险安排。保监局己制定一份有关《再保险指引》(指引17)阐述保监局评估保险公司的再保险安排是否足够时所采用的一般指导原则。', isHtml:true },
					{ type: 'redTitleLeft', val: '充足的偿付准备金' },
					{ type: 'typeTexts', val: '&emsp;&emsp;在保险资金的投资组合中，因为包含了权益类资产，且市场本身就存在各种风险，所以投资收益可能会存在不可预测的波动，而这种波动势必会影响投资收益的稳定性。因此，香港保险公司采用了一种调整机制来平滑收益。', isHtml:true },
					{ type: 'images', val: 'art6/art_6_6.png', minWidth: '80%' },
					{ type: 'typeTexts', val: '&emsp;&emsp;当投资收益超预期时，公司会将超出的部分存起来，等到市场环境较差，投资收益难达预期时，再将这部分资金拿出来补贴给客户。保险公司积累的未派发的超预期收益就像一个蓄水池，丰水期蓄水、枯水期放水，使客户获得的收益曲线保持一个较为平滑的趋势。', isHtml:true },
					{ type: 'redTitleLeft', val: '汇集保单' },
					{ type: 'typeTexts', val: '&emsp;&emsp;香港保险公司通常会将相同或类似的保单汇集，让较大组别的保单持有人共同分担风险。“汇集”的作用在于让投保人所属的保单组别分散和分担风险，并且有更大笔资金从而增加投资的灵活性。为确保分红保险产品的保单持有人之间的公平性，保险公司通常会分为例如：产品、产品更替、货币、投资年限等组别，务求每组保单将获得最能反映其保单表现的公平回报。', isHtml:true },
					{ type: 'typeTexts', val: '&emsp;&emsp;香港保险公司完整的信息披露让投资者清楚自己缴纳的保费到底投资了哪些资产，也明白了香港保险能够做到高收益与高赔付的原因。而严格的监管和保险公司自身对保障收益制度的创新，也为高收益与高赔付提供了强力保障。', isHtml:true },
				],
				[
					{ type: 'fristTitle', val: '读懂香港保险分红实现率' },
					{ type: 'newsTime', val: '发布时间：2023年11月20日' },
					{ type: 'divider', val: '' },
					{ type: 'typeTexts', val: '香港保险市场上的爆火保单大多都是分红保险，这些保单收益高达6%+甚至7%，怎么会有人不心动呢。但心动归心动，更多人是好奇这么高的分红收益真的可以实现吗？怎么才能判断保险公司是不是夸大收益呢？这就关系到分红实现率的问题了，这是衡量保险产品收益表现的重要依据。' },
					{ type: 'secondTitle', val: '分红险的由来' },
					{ type: 'typeTexts', val: '分红险最早出可追溯到200多年前的英国，当时是为抵御通胀和利率波动而设计推出的。1776年，英国公平人寿保险公司成立15周年进行决算时，发现实际责任准备金明显多于未来保险金支付所需准备金，于是决定将已交保费的10%返还给投保人，这就是世界上最早的分红保险，在欧美最初开展分红险的初衷也是希望消费者能够分享保险公司的经营盈余。分红储蓄险是人寿保险中以投资储蓄为主的险种，分红主要来源于保险公司的年终分红，因此选择盈利能力强的保险公司公司十分重要。简单来说可以理解为把钱交给保险公司专业投资团队管理，并在一定时期后获得投资回报，除了分红之外投保人在该时期也有对应额度的身故赔偿。所以大部分保险公司的投资团队会将大部分资金投资于高评级的固定收益产品，少部分投资股票及其他产品，来保证保单持有人的分红收益。' },
					{ type: 'secondTitle', val: '红利种类' },
					{ type: 'typeTexts', val: '在了解分红实现率之前，我们得搞清楚保险公司的分红是怎么分配的，有哪些种类。香港保险分红保单通常有三种红利派发方式，分别是<b>周年红利、复归红利和终期红利</b>。', isHtml:true },
					{ type: 'images', val: 'art7/art_7_1.png' },
					{ type: 'redTitleLeft', val: '周年红利' },
					{ type: 'typeTexts', val: '周年红利又称为<b>“现金红利”</b>，保险公司会以现金形式在每年派发红利，已派发的数额通常不会改变。投保人可以选择随时提取保单红利或者将其留在保险公司账户中，继续滚存非保证利息。如果选择将红利留在保险公司中，那么该保单的现金价值肯定会有所增加，有利于提升资产积累的进度。', isHtml:true },
					{ type: 'redTitleLeft', val: '复归红利' },
					{ type: 'typeTexts', val: '这是美式保单的叫法，在英式保单中被称为<b>“归原红利”</b>。复归红利它的面值会<b>永久附加于保单保额上</b>，并于受保人身故或指定时间点支付。如果希望提前兑现的话，就要<b>乘以变现折扣率</b>后以现金形式派发，变现折扣率是根据保单之年期以及当年的投资表现来决定的。复归红利会一直由保险公司管理，保险公司公布的红利只是一个数字，具体的价值只有在真正领取的时候才会知道。若投保人购买了复归红利产品，那么其保单保额将会随每年的红利增加，可以提高保单的保障水平和现金价值。', isHtml:true },
					{ type: 'redTitleLeft', val: '终期红利' },
					{ type: 'typeTexts', val: '又被称为<b>“特别红利”</b>，即一次性支付于保单终止时的红利，比如身故、退保或保单期满时。终期红利的面值及现金价值都是非保证的，并且不会永久附加于保单，其金额会于每次公布时更新，可能会比上一次公布时的金额更多或更少。若投保人购买了终期红利产品，在保单期满、身故或退保时就可以额外收到一笔一次性红利作为回报。', isHtml:true },
					{ type: 'secondTitle', val: '分红实现率' },
					{ type: 'typeTexts', val: '保险公司的“分红实现率”，这指的是一家保险公司过往的分红数据和其当时承诺的预期收益是否一致的一个标准。简单来说，以所有相关保单实际派发的累积非保证利益的总数额，除以其销售时在利益说明文件所述的总数额。接近100%的比率表示保险公司接近达到销售时预期的非保证利益。如果比率高于100%，则表示实际派发的金额高于销售时利益说明所描述的数额，反之亦然。透过分红实现率，我们可以直接的评估保险公司整体的投资能力和红利水平，可以作为挑选保险产品的一个重要依据。' },
					{ type: 'redTitleLeft', val: '分红实现率规定' },
					{ type: 'typeTexts', val: '<b>香港保监局2015年出台著名的GN16指引，要求香港保险公司在官网披露从2010年起，以及最近5年内曾发出新单的分红产品的实现率。</b>', isHtml:true },
					{ type: 'images', val: 'art7/art_7_2.png' },
					{ type: 'typeTexts', val: '<b>2023年GN16指引更新：</b>《承保长期保险业务（类别C业务除外》，要求各大保险公司必须把<b>2010年后曾发出的新保单</b>，及仍有有效保单存在的分红保单或万用寿险产品系列,保险人应在其公司网站披露非保证分红实现率，并把展示分红实现率网址告知客户。<b>（2024年1月1日生效）</b>', isHtml:true },
					{ type: 'images', val: 'art7/art_7_3.png', minWidth:'90%' },
					{ type: 'thirdTitle', val: '如何计算分红实现率?' },
					{ type: 'typeTexts', val: '简单来说，分红实现率可以理解为：以所有相关保单实际派发的累积非保证利益的总数额，除以其销售时于利益说明文件所述的总数额。接近 100%  的比率表示保险公司接近达到销售时预期的非保证利益。如果比率高于100%，则表示实际派发金额高于销售时利益说明所述的数额，反之亦然。' },
					{ type: 'images', val: 'art7/art_7_5.png',minWidth:'70%' },
					{ type: 'thirdTitle', val: '例子 1：包含周年红利和终期红利的分红保单' },
					{ type: 'typeTexts', val: '<b>假设该保单于2020年1月1日发出:</b>', isHtml:true },
					{ type: 'images', val: 'art7/art_7_6.png',minWidth:'90%' },
					{ type: 'typeTexts', val: '假设今天是2021 年 1 月 1 日，保险公司派发的周年红利为$1,000。首个保单年度的周年红利的实现率便是 1,000 /  1,000 = 100%。就终期红利而言，由于销售时利益说明所述数额及实际派发的金额均为$0，因此终期红利的实现率并不适用。' },
					{ type: 'typeTexts', val: '假设保单在 2022 年 1 月 1 日仍然有效，保险公司派发的周年红利为  $800，加上累积利息$30，累积红利及利息总额为$1,830。第二个保单年度的周年红利的实现率便是 1,830 / 2,030 =  90.1%。就终期红利而言，假设此时终止保单，而实际派发的终期红利金额为 $70  （销售时利益说明所述为$100），第二个保单年度的终期红利的实现率便是70 / 100 = 70%。' },
					{ type: 'thirdTitle', val: '例子 2：包含复归红利和终期红利的分红保单' },
					{ type: 'typeTexts', val: '<b> 假设该保单于2020年1月1日发出：</b>', isHtml:true },
					{ type: 'images', val: 'art7/art_7_7.png',minWidth:'90%' },
					{ type: 'typeTexts', val: '假设今天是2021年1月1日，保险公司派发的累积复归红利的现金价值为$950。首个保单年度的复归红利的实现率便是950 / 1,000 = 95%。就终期红利而言，由于销售时利益说明所述数额及实际派发的金额均为$0，因此终期红利的实现率并不适用。' },
					{ type: 'typeTexts', val: '假设保单在 2022 年 1 月 1  日仍然有效，保险公司派发的累积复归红利的现金价值为$1,720。第二个保单年度的复归红利的实现率便是1,720 / 2,000 =  86%。就终期红利而言，假设此时终止该保单，而实际派发的终期红利的现金价值为$50（销售时利益说明所述为$100）。第二个保单年度的终期红利的实现率是50  / 100 = 50%。请注意，分红实现率是以红利的现金价值来计算。' },
					{ type: 'redTitleLeft', val: '分红实现率常见问题' },
					{ type: 'typeTexts', val: '分红保单属长期性质 ，一般而言，保单初期的预期红利较少，保险公司相对容易做到接近100%的实现率。 而愈到保单的后期，红利金额一般会愈大 ，所以保单年度较后的比率或更能反映其长期表现。', mark: true },
					{ type: 'typeTexts', val: '红利有不同的种类，例如 周年红利、复归红利及终期红利 。基于不同红利的特点，保险公司会分别披露不同种类的分红实现率。如果分红保单会派发多于一种红利，可以查看利益说明文件了解各种红利的占比。', mark: true },
					{ type: 'typeTexts', val: '即使两份保单在同一年度生效，每份保单实际派发的非保证利益与销售时所预期的金额之间的差异，亦可能有所不同。由于分红实现率是计算每个保单年度内，所有相关保单的平均值，因此 个别保单的实际分红实现率可能与保险公司列出的分红实现率有所不同。', mark: true },
				],
			]
			
			
		}
	},
}